/*  hide native title */
.entry-title.taglib-categorization-filter{
  display:none;
}

.pna-logo {
  display: flex;
  align-items: center;
}
.logo-image{
  width: 170px;
}
.pna-logo:hover{
  text-decoration: none !important;
}
.portlet {
  margin-bottom: 0 !important;
}
.navbar-collapse {
  padding-right: 0 !important;
}
.header-container {
  display: flex;
  align-items: center;
  padding: 0 34px;
  height: 80px;
}
.navbar-container{
  display: flex;
  align-items: center;
  margin-inline-start: auto;
}
.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline-end: 67px;
  margin-inline-start: 0 !important;
}
.pna-navbar-img-logo {
  height: 59.58px;
  width: 55px;
  margin-inline-end: 12px;
}
.navbar-text-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13px;
  text-align: start;
  width: 110px;
}
.logo-ar {
  font-family: Almarai;
}
.navbar-text-logo-black{
  color: #4b555a;
}
.pna-header {
  backdrop-filter: blur(3px);
  z-index: 99;
  background-color: rgba(120,216,183,0.55);
}
.pna-header-white {
  background-color: white !important;
}
/*  Toplinks  */
.nav-items-container {
  display: flex;
  float: right;
}
.nav-item-container {
  display: flex;
  padding: 8px 14px 8px 16px;
  border-radius: 20px;
  background-color: #ffffff;
  white-space: nowrap;
  text-align: center;
}
.nav-item-container:nth-child(1) {
  margin-inline-end: 16px;
}
.nav-item-container:nth-child(2) {
  border: solid 1px #ffffff;
  background: #78d8b7;
}
.nav-item {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.71;
  color: #78d8b7;
  margin-inline-start: 6px;
}
.nav-item-link {
  text-decoration: none;
  color: inherit;
}
.nav-item-container:nth-child(2) > .nav-item {
  color: #ffffff;
}
/* Categories */
.pna-main-categories {
  display: flex;
  margin-inline-start: 100px;
  height: 100%;
}
.category-item {
  margin-inline-end: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.category-item > .category-item-link {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}
.category-item-inactive {
  color: #c9c9c9 !important;
}
.category-item-active {
  color: #78d8b7 !important;
}
.active-category {
  border-bottom: 3px solid #ffffff;
}
img.category-arrow {
  display: inline-block;
  height: 6px;
  width: 11px;
  margin-inline-start: 12px;
}
/* Subnavbar */
.sub-navbar-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: auto;
  background-color: #ffffff;
  display: none;
}
.sub-navbar-show {
  display: block;
}
.sub-categories-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  background-color: #ffffff;
  border-color: #f0f0f2;
  border-style: solid;
  border-width: 1px 0;
  padding: 0 30px;
}
.sub-category-item {
  font-size: 14px;
  border-radius: 16px;
  color: #9FAFB9;
  background-color:#ffffff;
  padding: 5px 15px;
  cursor: pointer;
  margin-inline-end: 30px;
  line-height: 1.71;
}
.sub-category-item:hover {
  background-color: #f1fbf7;
}
.sub-category-item:last-child {
  margin-inline-end: 0;
}
.sub-category-item-active {
  color: #78d8b7;
  background-color: #f1fbf7;
  font-weight: 700;
}
.thematics-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0;
  background-color: #ffffff;
  border-bottom: 1px #f0f0f2 solid;
}
.thematic-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 10px;
  border-bottom-width: 1px;
  cursor: pointer;
}
.thematic-card:hover {
  background-color: #f1fbf7;
}
.thematic-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  text-align: center;
  color: #01032f;
}
.thematic-description {
  font-size: 12px;
  line-height: 1.75;
  text-align: center;
  color: #212121;
}
.thematic-description-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Listing articles */
.listing-article-content {
    display: flex;
    padding: 45px 30px 100px;
}
.articles-list-container {
    width: 55%;
}
.useful-links-container {
    width: 45%;
}

#recent-articles-section {
  border-top: 1px solid #f0f0f2;
  padding-top: 30px;
}

#promoted-article-section {
  margin-bottom: 30px;
}

.recent-article {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    "details thumbnail"
    "body thumbnail";
  padding: 30px 0 20px;
  border-bottom: 1px solid #f0f0f2;
}

.recent-article-details {
  grid-area: details;
  margin-bottom: 10px;
}

.recent-article-details > span,
.promoted-article-details > span {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.oval {
  width: 4px;
  height: 4px;
  margin: 0 5px;
  border-radius: 100%;
  background-color: #d8d8d8;
}

.recent-article-tag,
.promoted-article-tag {
  color: #b8b8b8;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  text-align: start;
}

.recent-article-date,
.promoted-article-date {
  color: #b8b8b8;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: start;
}

.recent-article-title,
.promoted-article-title {
  color: #01032f;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  text-align: start;
}

.recent-article-title > a,
.promoted-article-title > a,
.useful-links-container {
  text-decoration: none;
  color: inherit;
}

.promoted-article-title {
  font-size: 30px;
}

.recent-article-body,
.promoted-article-body {
  color: #677279;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: start;
}

.recent-article-body {
  grid-area: body;
}

.recent-article-thumbnail {
  grid-area: thumbnail;
  margin-inline-start: 30px;
}

.promoted-article-thumbnail > img {
  width: 100%;
  max-height: 400px;
}

.recent-article-thumbnail > img {
  width: 180px;
  height: 110px;
}

.useful-links-title {
  color: #01032f;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  text-align: start;
  margin-bottom: 15px;
}

.useful-links-container {
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: #f8f8fb;
  padding: 5px 10px;
  margin-bottom: 10px;
  min-height: 65px;
}

.useful-links-container > picture , .useful-links-container > img  {
  width: 55px;
  max-height: 55px;
  margin-inline-end: 12px;
}


.useful-links-details > .link-title {
  font-family: Helvetica;
  font-weight: normal;
}

.useful-links-details > p {
  color: #677279;
  font-size: 13px;
  line-height: 1;
  text-align: start;
  margin-bottom: 0;
}

.useful-links-link {
  margin-inline-start: auto;
  margin-inline-end: 15px;
}

.useful-links-details > h6 {
  color: #01032f;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: start;
  margin-bottom: 5px;
}

.useful-links-details > h6.useful-links-details-title {
  font-family: Montserrat;
}

.useful-links-link.fa-icon > i {
  color: #01032f88;
}

.useful-links-container > img.svg-icon {
  width: 30px;
}

#liqahcorona-section,
#government-council-report-section {
  margin-top: 30px;
}



/* ----------------------------------------------------------*/
/* ---------------------DropDown Style ----------------------*/


.actualite-dropdown-container {
  display: flex;
}
.actualite-dropdown-container > div:first-child {
    margin-inline-end: 10px;
}

.actualite-dropdown-content {
  width: 160px;
}
.actualite-dropDown-style {
  box-shadow: none !important;
  background-color: white !important;
  border-radius: 25px !important;
  padding-inline-start: 5px;
  width: 100%;
  background: white;
  color: black;
  margin-inline-start: -1px;
  border: 2px solid #78d8b7 !important;
  height: 50px;
}
.actualite-dropDown-style-no-border {
  border-bottom-right-radius: 0;
}

.actualite-dropdown-placeholder {
  margin-inline-end: 5px;
  color: #78d8b7;
  font-size: 16px;
}

.actualite-dropdown-menu {
  width: auto !important;
  border-radius: 8px;
  padding: 10px;
}

.actualite-dropdown-item {
  border-radius: 20px;
  padding: 10px;
}
.active {
  color: #78d8b7 !important;
  background-color: #f1fbf7 !important;
}

.actualite-dropdown-placeholder-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actualite-dropdown-icon {
  transform: rotate(180deg);
}

.show-more-articles-button {
  padding: 20px;
  border-radius: 30px;
  background-color: #78d8b7;
  width: 100%;
  border: none;
  outline: none;
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.89;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.show-more-articles-button-disabled {
  background-color: #e9e9e9;
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .header-container{
    padding: 0 14px;
  }
  .override-navbar-toggler {
    font-size: initial;
    outline:0;
  }
  .pna-main-categories {
    display: none;
  }
  .sub-navbar-container {
    display: none;
  }
  .actualite-dropdown-container {
    display: none;
  }

  /* Listing articles */
  .listing-article-content {
      flex-direction: column;
      padding: 15px 15px 100px;
  }
  .articles-list-container {
      width: 100%;
  }
  .useful-links-section {
      width: 100%;
      margin-inline-start: 0px;
  }

  #useful-links-section,
  #liqahcorona-section,
  #government-council-report-section {
    padding: 0 10px;
  }
  #liqahcorona-section,
  #government-council-report-section {
    margin-top: 20px;
  }

  #recent-articles-section {
    border-top: none;
  }

  .recent-article {
    grid-template-areas:
            "details thumbnail"
            "body body";
  }

  .recent-article-thumbnail {
    margin-inline-start: 20px;
  }

  .promoted-article-title {
    font-size: 26px;
  }

  .recent-article-title {
    font-size: 20px;
  }

  .recent-article-thumbnail > img {
    width: 110px;
    height: 65px;
  }

  .promoted-article-details {
    padding: 0 10px;
  }
}